<template>
  <div class="news">
    <div class="container flex-col flex-center">
      <div class="flex-row title-container">
        <h2
          class="title"
          v-html="title"
        />
        <a
          :href="topCtaLink"
          class="top-cta"
        >
          Tout voir
          <UikitChevronIcon
            class="chevron-icon"
            type="right"
          />
        </a>
      </div>
      <div
        v-if="articles && articles.length"
        class="content-container"
      >
        <article-card
          v-for="(article, index) in articles"
          :key="`blog${index}`"
          :article="article"
          :parentCategorySlug="getParentSlug(article)"
          class="img-card"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { UikitChevronIcon } from '@hz/ui-kit'

export default {
  components: { UikitChevronIcon },
  props: {
    content: { required: true, type: Object },
    topCtaLink: { type: String, required: false },
    title: { type: String, required: true },
  },
  computed: {
    articles() {
      if (!this.content?.articles) {
        return []
      }

      return this.content?.articles?.data ?? this.content?.articles ?? []
    },
  },
  methods: {
    getParentSlug(article) {
      if (article.parent_category) {
        return article.parent_category.slug
      } else {
        return this.content.parentCategory.slug
      }
    },
  },
}
</script>
<style lang="less" scoped>
.title-container {
  gap: 24px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.chevron-icon {
  margin-bottom: 2px;
}
.top-cta {
  font-weight: 500;
  font-size: 20px;
  text-decoration: none;
  color: var(--ds-color-primary-100);
}

.title {
  font-size: 24px;
  font-weight: var(--ds-weight-semi-bold);
  max-width: 240px;
  transition-delay: 100ms;

  strong {
    color: var(--ds-color-primary-100);
    font-weight: inherit;
  }
}

.img-card {
  margin-bottom: 20px;
}

.container {
  margin: 50px 20px;
  gap: 32px;
}

.content-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  justify-content: flex-start;
  gap: 32px;
}

@media @bp-desktop {
  .container {
    gap: 50px;
  }

  .news {
    margin: 0 100px;
  }

  .content-container {
    overflow-y: hidden;
    width: auto;
    flex-direction: row;
    overflow-x: unset;
    justify-content: center;
    max-width: 1138px;

    .img-card {
      margin-bottom: 40px;
    }

    flex-wrap: wrap;

    a :deep(img) {
      max-height: 180px;
    }
  }

  .title-container {
    max-width: 1138px;
  }

  .title {
    font-size: 32px;
    max-width: 100%;
  }
}
</style>
